/* eslint-disable jsx-a11y/anchor-is-valid */
import { LinkConfig } from "../styles/Link.config";
import { LinkVariant } from "../types/Link.constants";
import { useLink, UseLinkProps } from "../utils/use-link";
import {
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import NextLink from "next/link";
import React, { useMemo } from "react";
import { LinkProps as NextLinkProps } from "next/link";
import { TLinkConfigReturn } from "../types/Link.config.types";
import { cssVars } from "@hybrbase/themes";

export interface LinkData {}

export interface LinkOptions extends UseLinkProps {
  children?: React.ReactNode;
  locale?: string | false;
  /**
   * Remove the top space of a text element that is caused by the element line-height
   */
  lhCrop?: number;
}

export interface LinkProps
  extends Omit<NextLinkProps, keyof UseLinkProps>,
    LinkOptions,
    LinkData {
  variant?: LinkVariant;
}

type LinkParts = ForwardRefComponent<"a", LinkProps>;

/**
 * Link are accessible elements used primarily for navigation
 */
export const Link: LinkParts = forwardRef<LinkProps, "a">((props, ref) => {
  const {
    variant = LinkVariant.Default,
    state,
    isOpenNewTab = false,
    className,
    children,
    title,
    href = "",
    as,
    prefetch,
    locale = "en",
    download,
    target,
    lhCrop = 0,
    ...rest
  } = props;

  const { ...ctx } = useLink({
    state,
    href,
    isOpenNewTab,
    target,
    isDownload: Boolean(download),
  });

  const { styles }: TLinkConfigReturn = useCompConfig(LinkConfig, {
    variant,
    css: {
      state: ctx.state,
    },
  });

  return useMemo(() => {
    const sharedProps = {
      title,
      target: ctx.htmlTarget,
      rel: ctx.htmlRel,
      className: cx(styles.Root, className),
      ref,
      role: "link",
      tabIndex: ctx.tabIndex,
    };

    if (ctx.isInternalLink) {
      return (
        <NextLink
          href={href}
          prefetch={prefetch}
          locale={locale}
          legacyBehavior
          {...rest}
        >
          <a data-variant={variant} data-comp="link" {...sharedProps}>
            {lhCrop && typeof children === "string" ? (
              <span
                className="block h-0 w-0 -mt-[var(--text-lh-crop)]"
                style={
                  {
                    ["--text-lh-crop"]: `calc(${cssVars.lineHeight.body} * ${lhCrop}rem)`,
                  } as JSX.IntrinsicElements["style"]
                }
              />
            ) : null}
            {children}
          </a>
        </NextLink>
      );
    } else {
      return (
        <a
          href={href.toString()}
          data-variant={variant}
          data-comp="link"
          download={download}
          {...rest}
          {...sharedProps}
        >
          {lhCrop && typeof children === "string" ? (
            <span
              className="block h-0 w-0 -mt-[var(--text-lh-crop)]"
              style={
                {
                  ["--text-lh-crop"]: `calc(${cssVars.lineHeight.body} * ${lhCrop}rem)`,
                } as JSX.IntrinsicElements["style"]
              }
            />
          ) : null}
          {children}
        </a>
      );
    }
  }, [
    lhCrop,
    children,
    variant,
    href,
    prefetch,
    locale,
    ctx,
    styles,
    title,
    ref,
    className,
    download,
    rest,
  ]);
});

Link.displayName = "Link";

export default Link;
