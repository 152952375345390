import {
  ButtonParts,
  ButtonSize,
  ButtonTheme,
  ButtonVariant,
} from "../types/Button.constants";
import { TButtonConfigProps } from "../types/Button.config.types";
import { Status } from "@hybrbase/themes";

export const ButtonConfig: TButtonConfigProps = {
  parts: Object.values(ButtonParts),
  css: {
    variants: {
      [ButtonVariant.Default]: {
        Root: "btn text-inherit text-center rounded-full font-primary normal-case group inline-flex items-center font-bold transition-colors duration-base ease-in-out",
        Dot: "block rounded-full transition-colors duration-base ease-in-out",
      },

      [ButtonVariant.Outline]: {
        Root: "btn group bg-transparent font-bold transition-colors duration-base ease-in-out",
        props: {
          theme: {
            primary: {
              Root: "border-contrast-low text-contrast-high hover:bg-contrast-high hover:text-primary hover:border-contrast-high focus:border-contrast-high  focus:bg-contrast-high focus:text-primary",
            },
          },
        },
      },
    },
    props: {
      theme: {
        [ButtonTheme.Primary]: {
          Root: "bg-transparent border-contrast-low text-contrast-high hover:!text-accent",
          Dot: "bg-contrast-low group-hover:bg-accent",
        },
      },
      size: {
        [ButtonSize.Sm]: {
          Root: "px-sm py-xs text-xs",
          Dot: "w-xxs h-xxs mr-xs",
        },
        [ButtonSize.Md]: {
          Root: "!px-fluid-md-to-lg !py-fluid-sm-to-md text-fluid-xs",
          Dot: "w-xxs h-xxs sm:h-xs sm:w-xs mr-sm",
        },
        [ButtonSize.Wide]: {
          Root: "w-full",
        },
      },
      state: {
        [Status.Disable]: {
          Root: "opacity-60 cursor-not-allowed",
        },
      },
    },
  },
};
