import { SectionVariant, SectionYSpace } from "../types/Section.constants";
import { useSection, UseSectionProps } from "../utils/use-section";
import {
  HTMLProps,
  cx,
  forwardRef,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";
import { SectionProvider } from "../utils/section-context";

export interface SectionData {
  /**
   * The subtitle of the section
   */
  subtitle?: string;
  /**
   * The title of the section as a header
   */
  title: string;
  /**
   * The text of the section to divided into paragraphs
   */
  text?: string;
}

export interface SectionOptions extends UseSectionProps {}

export interface SectionProps
  extends Omit<HTMLProps<"section">, keyof SectionData>,
    SectionOptions {}

type SectionParts = ForwardRefComponent<"section", SectionProps>;

/**
 * Section - The Section is a wrapper element to group components, and can be used to give space between other section.
 */
export const Section: SectionParts = forwardRef<SectionProps, "section">(
  (props, ref) => {
    const {
      variant = SectionVariant.Default,
      theme,
      ySpace = SectionYSpace.Default,
      className,
      children,
      ...rest
    } = props;
    const { ...ctx } = useSection({ ySpace, variant, theme });
    const context = React.useMemo(() => ctx, [ctx]);

    return (
      <SectionProvider value={context}>
        <section
          data-comp="section"
          data-variant={variant}
          data-theme-element={theme}
          className={cx("section", ctx.styles.Root, className)}
          ref={ref}
          {...rest}
        >
          {children}
        </section>
      </SectionProvider>
    );
  }
);

Section.displayName = `Section`;

export default Section;
