import {
  ContainerParts,
  ContainerSize,
  ContainerVariant,
} from "../types/Container.constants";
import { TContainerConfigProps } from "../types/Container.config.types";

export const ContainerConfig: TContainerConfigProps = {
  parts: Object.values(ContainerParts),
  css: {
    variants: {
      [ContainerVariant.Default]: {
        Root: "relative py-fluid-xxl-to-7xl",
      },
    },
    props: {
      size: {
        [ContainerSize.Sm]: {
          Root: "max-w-sm",
        },
        [ContainerSize.Md]: {
          Root: "max-w-sm sm:max-w-md",
        },
        [ContainerSize.Lg]: {
          Root: "max-w-sm sm:max-w-lg",
        },
        [ContainerSize.Full]: {
          Root: "max-w-full",
        },
      },
    },
  },
};
