import { createContext } from "@hybrbase/system";
import { UseSectionReturn } from "./use-section";

const [SectionProvider, useSectionContext] = createContext<UseSectionReturn>({
  name: "SectionContext",
  errorMessage:
    "useSectionContext: `context` is undefined. Seems you forgot to wrap all Section's components within <Section />",
});

export { SectionProvider, useSectionContext };
