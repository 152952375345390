import {
  SectionParts,
  SectionVariant,
  SectionYSpace,
} from "../types/Section.constants";
import { TSectionConfigProps } from "../types/Section.config.types";

export const SectionConfig: TSectionConfigProps = {
  parts: Object.values(SectionParts),
  css: {
    variants: {
      [SectionVariant.Default]: {
        Root: "relative",
      },
    },
    props: {
      ySpace: {
        [SectionYSpace.Default]: {},
        [SectionYSpace.None]: {
          Root: null,
        },
      },
    },
  },
};
