export enum ContainerParts {
  Root = "Root",
}

export enum ContainerVariant {
  Default = "default",
}

export enum ContainerSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Full = "full",
}
