import { useCompConfig } from "@hybrbase/system";
import { ThemeElement } from "@hybrbase/themes";
import { SectionConfig } from "../styles/Section.config";
import { SectionVariant, SectionYSpace } from "../types/Section.constants";
import { TSectionConfigReturn } from "../types/Section.config.types";

export interface UseSectionProps {
  /**
   * Variants for `Section`. You can extend the variant.
   */
  variant?: SectionVariant;

  theme?: ThemeElement;

  ySpace?: SectionYSpace;
}

/**
 * Section hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useSection = (props: UseSectionProps) => {
  const { variant, theme, ySpace } = props;

  const { styles }: TSectionConfigReturn = useCompConfig(SectionConfig, {
    variant,
    css: { ySpace, theme },
  });

  return { variant, styles };
};

export type UseSectionReturn = ReturnType<typeof useSection>;
