import { createContext } from "@hybrbase/system";
import { Status } from "@hybrbase/themes";
import { useMemo } from "react";

import { UrlObject } from "url";

export interface UseLinkProps {
  /**
   * If `true`, the link will open in new tab
   */
  href: string | UrlObject;
  isOpenNewTab?: boolean;
  isDownload?: boolean | string;
  state?: Status;
  target?: "_self" | "_blank" | "_parent" | "_top";
}

/**
 * Link hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useLink = (props: UseLinkProps) => {
  const { state, href, isOpenNewTab, isDownload, target } = props;

  const { isExternalLink, isExternalSite } = useMemo(() => {
    const protocol = href && href.toString().split(":").shift();
    return {
      isExternalSite:
        protocol === "https:" || protocol === "http:" || protocol === "www.",

      isExternalLink:
        protocol === "https:" ||
        protocol === "http:" ||
        protocol === "mailto:" ||
        protocol === "tel:" ||
        protocol === "www.",
    };
  }, [href]);

  const { isInternalLink, isDisabled, htmlRel, htmlTarget } = useMemo(() => {
    return {
      isDisabled: state === Status.Disable,
      htmlRel: isOpenNewTab ? `noopener noreferrer` : undefined,
      htmlTarget: isOpenNewTab && !isDownload ? `_blank` : target,
      isInternalLink:
        href.toString().startsWith("/") &&
        !isExternalLink &&
        !isExternalSite &&
        !isDownload,
    };
  }, [
    state,
    href,
    isOpenNewTab,
    target,
    isExternalLink,
    isDownload,
    isExternalSite,
  ]);

  return {
    htmlTarget,
    htmlRel,
    isDisabled,
    isInternalLink,
    isExternalLink,
    isExternalSite,
    role: "link",
    tabIndex: isDisabled ? -1 : 0,
    state: isDisabled ? Status.Disable : state,
  };
};

export type UseLinkReturn = ReturnType<typeof useLink>;

const [LinkProvider, useLinkContext] = createContext<UseLinkReturn>({
  name: "LinkContext",
  errorMessage:
    "useLinkContext: `context` is undefined. Seems you forgot to wrap all Link's components within <Link />",
});

export { LinkProvider, useLinkContext };
