export enum ButtonParts {
  Root = "Root",
  Dot = "Dot",
}

export enum ButtonVariant {
  Default = "default",
  Outline = "outline",
  Icon = "icon",
  Reset = "reset",
}

export enum ButtonSize {
  Sm = "sm",
  Md = "md",
  Wide = "wide",
}

export enum ButtonTheme {
  Primary = "primary",
}
