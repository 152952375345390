export enum LinkParts {
  Root = "Root",
}

export enum LinkVariant {
  Default = "default",
  Underline = "underline",
  Fx = "fx",
  Reset = "reset",
}
