import {
  TextContainerAlign,
  TextContainerParts,
  TextContainerSize,
} from "../types/TextContainer.constants";
import { TTextContainerConfigProps } from "../types/TextContainer.config.types";

export const TextContainerConfig: TTextContainerConfigProps = {
  parts: Object.values(TextContainerParts),
  css: {
    variants: {},
    props: {
      size: {
        [TextContainerSize.Sm]: {
          Root: "max-w-sm",
        },
        [TextContainerSize.Md]: {
          Root: "max-w-sm sm:max-w-md lg:max-w-xl",
        },
        [TextContainerSize.Lg]: {
          Root: "max-w-md md:max-w-lg lg:max-w-xl",
        },
      },
      align: {
        [TextContainerAlign.Left]: {
          Root: "text-left mr-auto",
        },
        [TextContainerAlign.Center]: {
          Root: "text-center mx-auto",
        },
        [TextContainerAlign.Right]: {
          Root: "text-right left-auto",
        },
        [TextContainerAlign.CenterLeft]: {
          Root: "text-center mx-auto sm:text-left sm:mr-0",
        },
        [TextContainerAlign.LeftCenter]: {
          Root: "text-left mr-auto sm:text-center sm:ml-auto",
        },
      },
      theme: {},
    },
  },
};
