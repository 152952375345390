import { LinkParts, LinkVariant } from "../types/Link.constants";
import { TLinkConfigProps } from "../types/Link.config.types";
import { Status } from "@hybrbase/themes";

export const LinkConfig: TLinkConfigProps = {
  parts: Object.values(LinkParts),
  css: {
    variants: {
      [LinkVariant.Default]: {
        Root: "font-secondary text-fluid-xxs font-bold uppercase tracking-wide text-contrast-higher transition-colors duration-fast hover:text-contrast-medium focus-visible:outline-none focus-visible:text-contrast-medium",
      },
      [LinkVariant.Underline]: {
        Root: "font-secondary font-bold uppercase tracking-wide text-contrast-higher transition-colors duration-fast hover:text-contrast-medium focus-visible:outline-none focus-visible:text-contrast-medium",
      },
    },
    props: {
      state: {
        [Status.Disable]: {
          Root: "opacity-75 cursor-not-allowed no-underline",
        },
      },
    },
  },
};
