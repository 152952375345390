import { ButtonConfig } from "../styles/Button.styles";
import {
  ButtonSize,
  ButtonTheme,
  ButtonVariant,
} from "../types/Button.constants";
import { ButtonLink } from "./ButtonLink";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { Status } from "@hybrbase/themes";
import React from "react";
import { TButtonConfigReturn } from "../types/Button.config.types";

export interface ButtonData {
  /**
   * The text of the title attribute is displayed as a tooltip in most browsers
   */
  title: string;
}
export interface ButtonOptions {
  variant?: ButtonVariant;
  state?: Status;
  theme?: ButtonTheme;
  size?: ButtonSize;
}

export interface ButtonProps
  extends Omit<HTMLProps<"button">, keyof ButtonData>,
    ButtonOptions,
    ButtonData {}

type ButtonParts = ForwardRefComponent<"button", ButtonProps> & {
  Link?: typeof ButtonLink;
};

/**
 * The Button component is used to trigger an action, open a new page or event, such as submitting a form, opening a dialog, canceling an action, or performing a delete operation.
 */
export const Button: ButtonParts = forwardRef<ButtonProps, "button">(
  (props, ref) => {
    const {
      className,
      children,
      variant = ButtonVariant.Default,
      theme = ButtonTheme.Primary,
      size = ButtonSize.Md,
      state,
      title,
      type = "submit",
      role = "button",
      ...rest
    } = props;

    const { styles }: TButtonConfigReturn = useCompConfig(ButtonConfig, {
      variant,
      css: {
        theme,
        size,
        state,
      },
    });

    return (
      <button
        data-comp="button"
        data-variant={variant}
        className={cx(styles.Root, className)}
        ref={ref}
        title={title}
        aria-label={title}
        type={type}
        role={role}
        disabled={state === Status.Disable}
        {...rest}
      >
        <span className={styles.Dot} />
        {children}
      </button>
    );
  }
);

Button.Link = ButtonLink;

Button.displayName = `Button`;
Button.Link.displayName = `Button.Link`;

export default Button;
