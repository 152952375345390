import { TextContainerConfig } from "../styles/TextContainer.config";
import {
  TextContainerAlign,
  TextContainerSize,
  TextContainerVariant,
} from "../types/TextContainer.constants";
import {
  TTextContainerConfigReturn,
  TTextContainerHtml,
  TTextContainerLineHeight,
  TTextContainerVspace,
} from "../types/TextContainer.config.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { cssVars, ThemeElement } from "@hybrbase/themes";
import React from "react";

const textContainerAsHtml: Record<TextContainerVariant, TTextContainerHtml> = {
  [TextContainerVariant.Default]: "div",
  [TextContainerVariant.Article]: "article",
};

export interface TextContainerData {}

export interface TextContainerOptions {
  /**
   * Variants for `TextContainer`. You can extend the variant.
   */
  variant?: TextContainerVariant;
  /**
   * Use to override default Html tag
   */
  as?: TTextContainerHtml;
  /**
   * Use to set the size vertical spacing
   */
  vSpace?: TTextContainerVspace;
  /**
   * Use to set the size line-height
   */
  lineHeight?: TTextContainerLineHeight;
  /**
   * Use to set the size vertical spacing
   */
  align?: TextContainerAlign;
  /**
   * Set the unit based on unit of the typography
   */
  unit?: "em" | "rem";
  /**
   * Use to set the size vertical spacing
   */
  theme?: ThemeElement;
  size?: TextContainerSize;
}
export interface TextContainerProps
  extends Omit<HTMLProps<any>, keyof TextContainerData>,
    TextContainerOptions,
    TextContainerData {}

type TextContainerParts = ForwardRefComponent<any, TextContainerProps>;

/**
 *  Component to be applied to any block containing typography elements. It takes care of 1) vertical rhythm and 2) styling inline elements.
 */
export const TextContainer: TextContainerParts = forwardRef<
  TextContainerProps,
  "div"
>((props, ref) => {
  const {
    variant = TextContainerVariant.Default,
    theme,
    lineHeight = 1,
    vSpace,
    align,
    size,
    className,
    children,
    unit = "rem",
    ...rest
  } = props;

  const defaultSetting = React.useMemo(() => {
    const _unit =
      unit === "em" ? cssVars.spacing["unit-em"] : cssVars.spacing["unit-rem"];

    switch (variant) {
      case TextContainerVariant.Article:
        return {
          vSpace: vSpace || 1.5,
          lineHeight,
          unit: _unit,
        };
      default:
        return {
          vSpaceDefault: vSpace || 1,
          lineHeight,
          unit: _unit,
        };
    }
  }, [variant, vSpace, lineHeight, unit]);

  const { styles }: TTextContainerConfigReturn = useCompConfig(
    TextContainerConfig,
    {
      variant,
      css: {
        theme,
        align,
        size,
      },
    }
  );

  const Component = textContainerAsHtml[variant];

  return (
    <Component
      data-comp="text-container"
      data-variant={variant}
      data-theme-element={theme}
      className={cx("text-container", styles.Root, className)}
      style={
        {
          ["--text-container-line-height-multiplier"]:
            defaultSetting.lineHeight,
          ["--text-container-text-vspace-multiplier"]: defaultSetting.vSpace,
          ["--text-container-unit"]: defaultSetting.unit,
          ...rest.style,
        } as JSX.IntrinsicElements["style"]
      }
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  );
});

TextContainer.displayName = `TextContainer`;

export default TextContainer;
